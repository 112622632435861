import React, {Component} from 'react'
import Helmet from 'react-helmet'

import CaseStudiesList from '../../components/CaseStudiesList'
import ContactForm from '../../components/ContactForm'
import Layout from '../../components/Layout'

import './index.scss'

export default class CaseStudies extends Component {

  render() {
    return (
      <Layout>
        <div className="case-studies">
          <Helmet>
            <title>Our case studies | Mobility Labs, Inc.</title>
            <meta name="description" content="See what we have worked on recently" />
          </Helmet>
          <div className="banner">
            <h1>Case Studies</h1>
          </div>
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col">
                  <CaseStudiesList/>
                </div>
              </div>
            </div>
          </div>
          <ContactForm/>
        </div>
      </Layout>
    )
  }
}
